import React from "react";
import EmptyStateView from "../../components/EmptyStateView";
import DoneIcon from "@material-ui/icons/Done";
import {navigate} from "gatsby";
import {PublicNavbar} from "../../components/Navbars";

export default function FreeConsult() {
  return (
    <div>
      <PublicNavbar/>
      <EmptyStateView
        text="Your request has been received! Stijn will get back to you soon."
        icon={<DoneIcon/>}
        size="large"
        // buttonIconLeft
        buttonText="Return to homepage"
        onButtonClick={() => navigate("/")}
      />
    </div>
  );
}
